import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import OneSignal from 'onesignal-cordova-plugin';
import { environment } from '../../environments/environment';
import * as $ from 'jquery';

const apiV1 = environment.base_url + 'wp-json/mobileapi/v1/';
const apiV2 = environment.base_url + 'wp-json/wp/v2/';
const nft_base_url = 'https://api.nftport.xyz/v0/';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  loading: any;
  pages: any;
  totalPosts = null;
  idName: any = '';
  imgCat: any = '';
  constructor(
    private http: HttpClient,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    private platform: Platform,
    // private oneSignal: OneSignal,
    private toastController: ToastController,
  ) { }

  getURL() {
    return environment.base_url;
  }

  async dismissLoading() {
    return await this.loading.dismiss();
  }

  async showLoader(msg: string = '') {
    if (msg === '') {
      msg = 'Please wait...';
    }
    this.loading = await this.loadingCtrl.create({ message: msg, backdropDismiss: true });
    return await this.loading.present();
  }



  getData(endPoint) {
    return this.http.get(apiV1 + endPoint).pipe(
      map(data => data)
    );
  }

  sendData(endPoint, data) {
    return this.http.post(apiV1 + endPoint, data).pipe(
      map(result => result)
    );
  }

  doLogin(endPoint, data) {
    return this.http.post(environment.base_url + 'wp-json/jwt-auth/v1/' + endPoint, data).pipe(
      map(result => result)
    );
  }

  saveOneSignID(token, oneSignID, action_type = '') {
    return this.http.post(environment.base_url + 'wp-json/mobileapi/v1/save_onesignal_id/', {
      oneSignID: oneSignID,
      token: token,
      type: 'ios',
      timezone: '',
      user_auth_type: '',
      action_type: action_type,
    });
  }

  async SaveAutoConfiqure(token, action_type = '') {
    console.log('SaveAutoConfiqure :>> ', token);
    if (this.platform.is('cordova')) {
      console.log('platform:>>', this.platform);
      OneSignal.getDeviceState(res => {
        let temp: any = res;
        console.log(temp.userId);
        console.log('one signal id :>> ', temp);
        this.saveOneSignID(token, temp.userId, action_type).subscribe(m => {
          console.log('saveOneSignID', temp.userId);
        });
      });
    }
  }


  getPosts(page = 1, user, token, is_fav = false, selectedDate = false): Observable<any[]> {
    let user_id = user ? ("&user_id=" + user) : "";
    let token_url = token ? ("&token=" + token) : "";
    let is_fav_url = is_fav ? ("&is_fav=" + is_fav) : "";
    let choosenDate = selectedDate ? ("&selectedDate=" + selectedDate) : "";

    let options = {
      observe: "response" as 'body',
      params: {
        per_page: '10',
        page: '' + page
      }
    };

    return this.http.get<any[]>(apiV2 + 'tickets?_embed' + user_id + token_url + is_fav_url + choosenDate, options).pipe(
      map(resp => {
        this.pages = resp['headers'].get('x-wp-totalpages');
        this.totalPosts = resp['headers'].get('x-wp-total');

        let data = resp['body'];

        for (let post of data) {
          post.media_url = post['media_url'];
        }
        return data;
      })
    )
  }

  GetSetting() {
    return this.http.post(environment.base_url + 'wp-json/mobileapi/v1/GetSetting', {});
  }

  CreateStripeUser(token, stripeToken, type: number = 0) {
    return this.http.post(environment.base_url + 'wp-json/mobileapi/v1/saveCards', {
      token: token,
      stripeToken: stripeToken,
      type: type,
    });
  }

  GetCards(token) {
    return this.http.post(environment.base_url + 'wp-json/mobileapi/v1/getSaveCards', {
      token: token,
    });
  }

  removeCard(token, card, type) {
    return this.http
      .post(environment.base_url + 'wp-json/mobileapi/v1/deleteCard', {
        token: token,
        card: card,
        type: type,
      })
      .pipe(
        retry(2),
        map((content) => {
          return content;
        })
      );
  }

  updateCard(token, card, card_new, type) {
    return this.http
      .post(environment.base_url + 'wp-json/mobileapi/v1/updateCard', {
        token: token,
        card: card,
        name: card_new.name,
        expMonth: card_new.expMonth,
        expYear: card_new.expYear,
        type: type,
        default: card_new.default,
      })
      .pipe(
        retry(2),
        map((content) => {
          return content;
        })
      );
  }

  upload_nft_file(endPoint, data) {
    return this.http
      .post(nft_base_url + endPoint, data, {
        "headers": {
          "authorization": environment.nft_api,
        }
      })
      .pipe(
        retry(2),
        map((content) => {
          return content;
        })
      );
  }



  getIsCoinBase(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let show_coinbase: boolean = false;
      this.getData('enableDisableCoinbase').subscribe(res => {
        if (res['status'] == 'ok') {
          show_coinbase = res['show_coinbase'];
        }
        resolve(show_coinbase);
      }, err => {
        reject;
        console.log(err);
      })

    });

  }


  getToken() {
    return JSON.parse(window.localStorage.getItem("token"));
  }

  public removeFileSelector() {
    $('.cordova-camera-select').remove();
  }


  async triggerImgSections(idName, imgCat) {
    $(`#${idName}`).trigger('click');
    this.idName = idName;
    this.imgCat = imgCat;
  }

  // handleFileImg(ev) {
  //   let data : {
  //     img: '',
  //     cat: ''
  //   }
  //   return new Promise(async (resolve)=>{
  //     let base64Img :any = '';
  //     if (ev.target.files[0]) {
  //       let fileImg = ev.target.files[0];
  //       console.log(fileImg)
  //       var reader: FileReader = new FileReader();
  //       reader.onload = () => {
  //         base64Img = reader.result;
  //         data['img'] = base64Img;
  //         data['cat'] = this.imgCat
  //       };
  //       await reader.readAsDataURL(fileImg);
  //     }
  //     resolve(data);
  //   });

  // }


  handleFileImg(ev) {
    let data: any = {
      img: '',
      cat: ''
    };

    return new Promise(async (resolve) => {
      let base64Img: any = '';
      if (ev.target.files[0]) {
        let fileImg = ev.target.files[0];
        console.log(fileImg);


        const fileInput: any = document.getElementById(this.idName);
        // Clear the value after file selection
        fileInput.value = ''; // This allows selecting the same file again



        var reader: FileReader = new FileReader();
        reader.onload = () => {
          base64Img = reader.result;
          // Remove the "data:image/png;base64," or any similar prefix
          base64Img = base64Img.replace(/^data:image\/[a-zA-Z]+;base64,/, '');

          data.img = base64Img as string; // Explicitly cast to string
          data.cat = this.imgCat;
          resolve(data); // Resolve inside onload to ensure the data is returned after reading the file
        };

        reader.readAsDataURL(fileImg); // Trigger file read
      } else {
        resolve(data); // In case no file is selected
      }
    });
  }


}
