import { Component, NgZone } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { AlertControllerService } from './services/alert-controller.service';
import { NavigationService } from './services/navigation.service';
import { StorageService } from './services/storage.service';
import { CommonService } from './services/common.service';
import { Events } from './services/common/events.service';
// import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links/ngx';
import { ApiService } from './services/api.service';
import { Location } from '@angular/common';
import { AlertController } from '@ionic/angular';
import * as firebase from 'firebase/compat/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import OneSignal from 'onesignal-cordova-plugin';

import { Deeplinks } from '@ionic-native/deeplinks/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public rootPage: any;
  public appPages = [];
  loginUser: any = [];
  role: any;
  selectedIndex = 0;
  // is_selectMenu : boolean = false;
  selectMenu: any = '';
  type: string;
  isLoader: boolean = false;
  res: Object;
  app: any;

  constructor(
    private router: Router,
    private platform: Platform,
    private alertControllerService: AlertControllerService,
    private storageService: StorageService,
    private naviGation: NavigationService,
    private menu: MenuController,
    // private oneSignal: OneSignal,
    private commonService: CommonService,
    private events: Events,
    // private firebaseDynamicLinks: FirebaseDynamicLinks,

    private deeplinks: Deeplinks,

    private ngZone: NgZone,
    private navCtrl: NavController,
    private apiService: ApiService,
    public location: Location,
    public altCtrl: AlertController

  ) {
    this.initializeApp();
    this.storageService.getFromNativeStorage('cb_user').then((user) => {
      if (user != null) {
        this.loginUser = JSON.parse(user);
        console.log('loginUser :>>', this.loginUser);
        this.initializeMenu(this.loginUser);
        this.getUserProfile(this.loginUser.token, true);
        // this.check_coinbase_connection(this.loginUser);
      }
    });
    this.events.subscribe('user_login', (user) => {
      console.log('events', user);
      this.loginUser = JSON.parse(user);
      console.log('loginUser from the event:>>', this.loginUser);
      this.initializeMenu(this.loginUser);
      // this.check_coinbase_connection(this.loginUser);
    })

    this.events.subscribe('select_menu', ((selectMenu) => {
      if (selectMenu == 'highLight_menu') {
        this.selectedIndex = 0;
      }
      console.log('selectMenu :>> ', this.selectMenu);
    }))
    this.events.subscribe('approved_host', async (val) => {
      console.log("val of subscribe event", val);
      this.role_updation();
    })
  }
  async role_updation() {
    const alert = await this.altCtrl.create({
      header: 'Alert',
      // message: "Your request for becoming a crypto bezel host has been approved, please login again to access host functionality!",
      message: "Your request for becoming a cb stay host has been approved, please login again to access host functionality!",
      backdropDismiss: false,
      buttons: [{
        text: "OK",
        handler: () => {
          this.router.navigate(['/login']);
        }
      }],

    });

    await alert.present();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      const firebaseConfig = {
        apiKey: "AIzaSyCqxt34AWlGnGj7zP4aJWxQbPVeGm_hLwE",
        authDomain: "cb-stay.firebaseapp.com",
        projectId: "cb-stay",
        storageBucket: "cb-stay.appspot.com",
        messagingSenderId: "8193628326",
        appId: "1:8193628326:web:33a534a3229d0e50d67c67",
        databaseURL: 'https://cb-stay-default-rtdb.firebaseio.com/'
      };

      // Initialize Firebase
      this.app = firebase.default.initializeApp(firebaseConfig);

      if (this.platform.is('cordova')) {
        await this.onesignalInit();
      }


      console.log('reload pltform:>', this.platform);
      // if (this.platform.is('android')) {
      if (!this.platform.is('ios') && !this.platform.is('android')) {
        console.log('outside os ios and android platform.');
        // this.naviGation.gotopage('/loader');
        return false;
      } else {
        this.newOpneDeepLink();
      }


    });

  }

  onesignalInit() {
    OneSignal.setAppId('467b0612-7abb-402b-a7f5-5203b842d4af');

    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log("User accepted notifications: " + accepted);
    });

    OneSignal.setNotificationOpenedHandler(function (data: any) {
      this.storageService.getFromNativeStorage('cb_user').then((user) => {
        console.log("user", user);
        if (user != null) {
          this.loginUser = JSON.parse(user);
          console.log('loginUser :>>', this.loginUser);
          console.log('handleNotificationOpened  data:>> ', data);
          console.log('Notification Data Stringify opend function: ' + JSON.stringify(data));

          let notification_data = data.notification.payload.additionalData;
          if (notification_data.type == 'host_message') {
            console.log('notification_data.type :>> ', notification_data.type);
            if (notification_data.post_type == 'property') {
              if (notification_data.property_type == 'sale') {
                console.log('notification_data.property_type :>> ', notification_data.property_type);
                this.router.navigate(['/tabs/properties-sale-detail/' + notification_data.post_id]);
              } else if (notification_data.property_type == 'rent') {
                this.router.navigate(['/tabs/properties-for-rent-details/' + notification_data.post_id]);
              }
            } else if (notification_data.post_type == 'tickets') {
              this.router.navigate(['/tabs/tickets-for-event-details/' + notification_data.post_id]);
            }
          } if (notification_data.booking_id) {
            console.log('notification_data.booking_id :>> ', notification_data.booking_id);
            this.router.navigate(['/message-details/' + notification_data.booking_id]);
          }
          console.log("noti_type2", notification_data.post_type);
          if (notification_data.post_type == "host_approved") {
            this.events.publish('approved_host', 1);
          }
        } else {
          this.commonService.presentAlert('Please login then see your notification.');
        }
      });
    });

    OneSignal.setNotificationWillShowInForegroundHandler(function (data) {
      console.log('Nootification received');
    })

    // OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
    //   this.events.publish('notification_received', 1);
    // });
  }


  async getUserProfile(userid: any, is_loader) {
    // if (is_loader) {
    // await this.apiService.showLoader();
    // }
    this.apiService
      .getData('getProfile/?token=' + userid + '&type=' + this.type).subscribe(async (data) => {
        this.res = data;
        // if (is_loader) {
        // await this.apiService.dismissLoading();
        // }
        if (this.res['status'] == 'ok') {
          console.log('User Profile:>>', this.res);
          console.log("this.res['role']", this.res['role']);
          this.loginUser.role = this.res['role'];
          this.storageService.saveToNativeStorage('cb_user', JSON.stringify(this.loginUser));
        }
      },
        async (err) => {
          // if (is_loader) {
          // await this.apiService.dismissLoading();
          this.commonService.presentAlert("Unable to update the role please login again");
          // }


        }
      );
  }


  logout() {
    // const alertHeader = 'Logout';
    // const alertMessage = 'Are you sure ?';
    const alertHeader = ' You are about to log out.';
    const alertMessage = 'Would you like to continue?';
    this.alertControllerService
      .presentAlertWithHeader(alertHeader, alertMessage)
      .then((userResponse) => {
        if (userResponse) {

          this.apiService.SaveAutoConfiqure(this.loginUser.token, 'logout');

          this.storageService.clearNativeStorage();
          this.menu.enable(false);
          this.naviGation.setRoot('/login');
        }
      });
  }


  initializeMenu(user) {
    console.log('initialize menu :>>', user);
    this.role = user.role;
    console.log('user on event subscribe: ', user);
    if (user) {
      if (this.loginUser.role == "host") {
        this.appPages = [
          {
            title: 'Home',
            url: '/tabs/host-home',
          },
          {
            title: 'Settings',
            url: '/setting',
          },
          {
            title: 'Ticket sales',
            url: '/tabs/ticket-sales',
          }, {
            title: 'My Tickets',
            url: '/my-tickets',
          },
          {
            title: 'Notifications',
            url: '/tabs/notifications',
          },
          {
            title: 'My Listings',
            url: '/tabs/my-listings-screen',
          },
          {
            title: 'My Events',
            url: '/tabs/tickets-for-event/' + this.loginUser.user_id,
          },
          {
            title: 'About Us',
            url: '/tabs/about-us',
          },
          {
            title: 'Contact Us',
            url: '/tabs/contact-us',
          },
          {
            title: 'My Bookmarks',
            url: '/tabs/my-bookmarks',
          },
          // {
          //   title: 'My Favorites',
          //   url: '/tabs/my-favorites-list',
          // },
          {
            title: 'TOS',
            url: '/tabs/term-of-service',
          },

        ];
      } else {
        this.appPages = [
          {
            title: 'Home',
            url: '/tabs/home',
          },
          {
            title: 'Settings',
            url: '/setting',
          },
          {
            title: 'My Tickets',
            url: '/my-tickets',
          },
          {
            title: 'Notifications',
            url: '/tabs/notifications',
          },
          {
            title: 'About Us',
            url: '/tabs/about-us',
          },
          {
            title: 'Contact Us',
            url: '/tabs/contact-us',
          },
          {
            title: 'My Bookmarks',
            url: '/tabs/my-bookmarks',
          },
          // {
          //   title: 'My Favorites',
          //   url: '/tabs/my-favorites-list',
          // },
          {
            title: 'TOS',
            url: '/tabs/term-of-service',
          },


        ];
      }

    } else {
      this.appPages = [];
    }
  }

  closeMenu() {
    this.menu.close();
  }

  newOpneDeepLink() {
    if (this.platform.is('android') || this.platform.is('ios')) {
      // return new Promise((resolve, reject) => {
      console.log('inside newOpneDeepLink function');
      this.deeplinks.routeWithNavController(this.navCtrl, {
        // '/app': '/',
      }).subscribe(async (match) => {
        console.log('routeWithNavController Successfully matched route', match);
        console.log('match.$link:>>', match.$link);
        console.log('match.$link?.path:>>', match.$link?.path);

        const url = match?.$link?.url || '';

        if (url) {
          var splitUrl = url.split('type=');
          let d = splitUrl[1].split("&");
          let type: any = d[0];
          let cat_name: any = d[1].replace(/_/g, ' ');
          console.log("splitUrl 1:", splitUrl);
          console.log("d 1:", d);
          console.log("type 1:", type);
          console.log("cat_name 1:", cat_name);

          if (type) {
            setTimeout(() => {
              if (type == 'realProperty') {
                this.navCtrl.navigateForward(['/favorites-list/realProperty']);
              } else if (type == 'virtualReality') {
                this.navCtrl.navigateForward(['/favorites-list/virtualReality']);
              } else if (type == 'educationalSpaces') {
                this.navCtrl.navigateForward(['/favorites-list/educationalSpaces']);
              } else if (type == 'springBreak') {
                this.navCtrl.navigateForward(['/favorites-list/springBreak']);

              } else if (type == 'trainingEvents') {

                this.navCtrl.navigateForward(['/favorites-list/trainingEvents']);

              } else if (type == 'concertTickets') {

                this.navCtrl.navigateForward(['/favorites-list/concertTickets']);

              } else if (type == 'forSale') {
                this.navCtrl.navigateForward(['/favorites-list/forSale']);
              } else {
                let navigation: NavigationExtras = {
                  queryParams: {
                    catName: cat_name
                  }
                }
                this.navCtrl.navigateForward(['/favorites-list/' + type], navigation);
              }
            }, 1000);

          } else {
            if (this.loginUser.role == 'host') {
              this.router.navigate(['/tabs/host-home']);
            }
          }
          var splitUrl1 = url.split('post_id=');
          let post_id: any = parseInt(splitUrl1[1]);
          if (post_id) {
            console.log('post_id :>> ', post_id);
            this.router.navigate([`/favorites-details/${post_id}`]);
          } else {
            if (this.loginUser.role == 'host') {
              this.router.navigate(['/tabs/host-home']);
            } else {
              this.router.navigate(['/tabs/home']);
            }
          }
        }

        // resolve(true);



      },
        (nomatch) => {
          console.error('routeWithNavController Got a deeplink that didn\'t match', nomatch);
          // reject(false);
        });
      // })
    }


  }


  check_coinbase_connection(rs) {
    signInWithEmailAndPassword(getAuth(), rs.user_email, 'sR123456').then((user) => {
      const db = getDatabase();
      const starCountRef = ref(db, 'coinbase_wallet_data/' + rs.user_id);
      console.log('firebase function', starCountRef);
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          console.log(data)
          if (data.refresh_now) {
            this.events.publish('reload_wallet_page', true);
          }
        }
      }, err => {
        console.log(err);
      });
    }, err => {
      if (err.code == 'auth/user-not-found') {
        createUserWithEmailAndPassword(getAuth(), rs.user_email, 'sR123456').then((user) => {
          this.check_coinbase_connection(rs);
        });
      }
    })
  }

}
